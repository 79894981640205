import Event from "common/event";
import { ActionWithDispatch } from "common/actionWithDispatch";
import Contract, { ContractType } from "domain/contract";
import contractService, { ListContractsFilter } from "./contractService";
import { ContractColumnConfigurations, defaultContractColumns } from "./ContractListColumns";
import { Dispatch } from "redux";
import trackApiCall, { trackApiCallNew } from "features/saveIndicator/trackApiCall";
import PagedData from "common/pagedData";
import loggingService from "common/logging/loggingService";

export class ContractsLoaded extends Event {
    constructor(public contractType: ContractType, public contracts: PagedData<Contract>) {
        super(ContractsLoaded.EventName)
    }

    public static EventName: string = "contracts/loaded";
}

export class ContractAdded extends Event {
    constructor(public contract: Contract) {
        super(ContractAdded.EventName)
    }

    public static EventName: string = "contract/added";
}

export class ContractsAdded extends Event {
    constructor(public contracts: Contract[]) {
        super(ContractsAdded.EventName)
    }

    public static EventName: string = "contracts/added";
}

export class ContractUpdated extends Event {
    constructor(public contract: Contract) {
        super(ContractUpdated.EventName)
    }

    public static EventName: string = "contract/updated";
}

export class ContractColumnConfigurationsLoaded extends Event {
    constructor(public columnConfigs: ContractColumnConfigurations) {
        super(ContractColumnConfigurationsLoaded.EventName)
    }

    public static EventName: string = "contract/columns-loaded";
}

export function loadContracts(contractType: ContractType, filter: ListContractsFilter): ActionWithDispatch<Promise<any>> {
    filter.contractType = contractType;

    return function (dispatch: any) {
        return contractService
            .listContracts(filter)
            .then(contracts => dispatch(new ContractsLoaded(contractType, { data: contracts.results, totalCount: contracts.totalCount }).toObject()))
            .catch((err) => {
               loggingService.error('Failed to load contracts', err);
                throw err;
            });
    }
}

export function createContract(contract: Contract): ActionWithDispatch<Promise<any>> {

    return trackApiCall(dispatch =>
        contractService.createContract(contract)
            .then((createdContract) => dispatch(new ContractAdded(createdContract).toObject()))
    );
}

export function priceBalances(contract: Contract): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        contractService.priceBalances(contract)
            .then((contracts) => dispatch(new ContractsAdded(contracts.results).toObject()))
    );
}

export function updateContract(contract: Contract): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        contractService.updateContract(contract)
            .then((updatedContract) => dispatch(new ContractUpdated(updatedContract).toObject()))
    );
}

export function updateContractFlag(dispatch: Dispatch, contract: Contract): Promise<any> {
    return trackApiCallNew(dispatch, innerDispatch => {
        innerDispatch(new ContractUpdated(contract).toObject());
        return contractService
          .patchContract(contract.id, { flag: contract.flag})
          .catch((error) => {
            dispatch(new ContractUpdated({...contract, flag: !contract.flag }).toObject());
            throw error;
          });
      });
};

export function closeContract(contract: Contract): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        contractService.closeContract(contract)
            .then((updatedContract) => dispatch(new ContractUpdated(updatedContract).toObject()))
    );
};

export function archiveContract(contract: Contract): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        contractService.archiveContract(contract)
            .then((updatedContract) => dispatch(new ContractUpdated(updatedContract).toObject()))
    );
};

export function unarchiveContract(contract: Contract): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        contractService.unarchiveContract(contract)
            .then((updatedContract) => dispatch(new ContractUpdated(updatedContract).toObject()))
    );
};

export function loadContractColumnConfigurations(dispatch: Dispatch<any>): any {
    return contractService
            .getColumnConfigurations()
            .then(columns => dispatch(new ContractColumnConfigurationsLoaded(columns ?? defaultContractColumns).toObject()));
}
export function saveContractColumnConfigurations(dispatch: Dispatch<any>, config: ContractColumnConfigurations) {
    contractService.updateColumnConfigurations(config);
    dispatch(new ContractColumnConfigurationsLoaded(config).toObject());
}