import ThrowsValidationError from "common/http/throwsValidationError";
import ListItemsResponse from "common/listItemsResponse";
import Contract, { ContractType } from "domain/contract";
import { ContractColumnConfigurations } from "./ContractListColumns";
import ReturnsNullOn404 from "common/http/returnsNullOn404";
import { DeliveryMethod } from "domain/deliveryMethod";
import { PaymentOption } from "domain/paymentOption";
import { formatUTCDate } from "common/formatters";
import { CurrencyEnum } from "domain/currency";
import PhysicalLocation from "domain/physicalLocation";
import Commodity from "domain/commodity";
import { Trader } from "features/settings/traders/trader";
import ContractHistory from "domain/contractHistory";
import ContractPricing from "domain/contractPricing";
import OfferHistory from "domain/offerHistory";
import PatchOperation, { OperationType } from "common/http/patchOperation";
import ContractSubType from "./contractSubType";
import { FeeThin } from "domain/fee";
import { CustomFieldThin } from "domain/customField";
import Throttle from "common/decorators/throttle";
import HttpService from "common/http/httpService";
import ThrowsAccessDenied from "common/http/throwsAccessDenied";
import ContractInventory from "domain/contractInventory";

class ContractService extends HttpService {
    
    async listContracts(filter?: ListContractsFilter): Promise<ListItemsResponse<Contract>> {
        const response = await this.getClient().get<ListItemsResponse<Contract>>('/offers/v1/contracts', {
            params: filter == null
                ? null
                : {
                    contractType: filter.contractType,
                    locations: filter.locations?.map(l => l.id).join(','),
                    commodities: filter.commodities?.map(c => c.id).join(','),
                    traders: filter.traders?.map(t => t.userId).join(','),
                    customerWillSell: filter.willSell,
                    startDate: filter.startDate,
                    endDate: filter.endDate,
                    displayContracts: filter.displayContracts,
                    customerId: filter.customerId,
                    customerAccount: filter.customerAccount,
                    deliveryPeriodId: filter.deliveryPeriodId
                }
        });
        return response.data;
    }
    
    @ReturnsNullOn404()
    @ThrowsAccessDenied()
    async getContract(contractId: number): Promise<Contract> {
        const response = await this.getClient().get<Contract>(`/offers/v1/contracts/${contractId}`);
        return response.data;
    }
    
    @ReturnsNullOn404()
    @ThrowsAccessDenied()
    async getContractByDisplayId(displayId: string): Promise<Contract> {
        const response = await this.getClient().get<Contract>(`/offers/v1/contracts/by-display-id/${displayId}`);
        return response.data;
    }
    
    @Throttle(1000, true)
    @ReturnsNullOn404()
    async getColumnConfigurations(): Promise<ContractColumnConfigurations> {
        const response = await this.getClient().get<ContractColumnConfigurations>('/settings/v1/user-preferences/contract-columns');
        return response.data;
    }

    @ThrowsValidationError()
    async updateColumnConfigurations(config: ContractColumnConfigurations): Promise<any> {
        await this.getClient().put('/settings/v1/user-preferences/contract-columns', config);
    }

    @ThrowsValidationError()
    async createContract(contract: Contract): Promise<Contract> {
        const request = this.GetCreateContractRequest(contract);
        const response = await this.getClient().post<Contract>("/offers/v1/contracts", request);
        return response.data;
    }

    @ThrowsValidationError()
    async priceBalances(contract: Contract): Promise<ListItemsResponse<Contract>> {
        const request = this.GetCreateContractRequest(contract);
        const response = await this.getClient().post<ListItemsResponse<Contract>>("/offers/v1/contracts/price-balances", request);
        return response.data;
    }

    private GetCreateContractRequest(contract: Contract): CreateContractRequest {
        const request: CreateContractRequest = {
            deliveryPeriodId: contract.deliveryPeriod?.id,
            futuresContractId: contract.futuresContract?.id,
            locationId: contract.location?.id,
            contractLocationId: contract.contractLocation?.id,
            regionId: contract.region?.id,
            customerId: contract.producer.id,
            customerAccountId: contract.customerAccount?.accountId,
            customerWillSell: contract.customerWillSell,
            contractType: contract.contractType,
            contractSubType: contract.contractSubType,
            commodityId: contract.commodity.id,
            currency: contract.currency,
            trader: contract.trader,
            deliveryMethod: contract.deliveryMethod,
            freightFee: contract.freightFee,
            deliveryTerms: contract.deliveryTerms,
            customerContractId: contract.customerContractId,
            premium: contract.premium,
            basis: contract.basis,
            cashPrice: contract.cashPrice,
            finalPrice: contract.finalPrice,
            futuresPrice: contract.futuresPrice,
            isBasisLocked: contract.isBasisLocked,
            quantityContracted: contract.quantityContracted,
            expirationDate: formatUTCDate(contract.expirationDate, "yyyy-MM-DD"),
            paymentOption: contract.paymentOption,
            deferredPayDate: contract.deferredPayDate ? formatUTCDate(contract.deferredPayDate, "yyyy-MM-DD") : null,
            interestStartDate: contract.interestStartDate ? formatUTCDate(contract.interestStartDate, "yyyy-MM-DD") : null,
            interestRate: contract.interestRate,
            comments: contract.comments,
            deliveryPeriodStartDate: contract.deliveryPeriodStartDate ? formatUTCDate(contract.deliveryPeriodStartDate, "yyyy-MM-DD") : null,
            deliveryPeriodEndDate: contract.deliveryPeriodEndDate ? formatUTCDate(contract.deliveryPeriodEndDate, "yyyy-MM-DD") : null,
            sourceContractId: contract.sourceContractId,
            fees: contract.fees,
            customFields: contract.customFields,
            disableErpSync: contract.disableErpSync
        };

        return request;
    }

    @ThrowsValidationError()
    async updateContract(contract: Contract): Promise<Contract> {

        const request: UpdateContractRequest = {
            contractSubType: contract.contractSubType,
            deliveryPeriodId: contract.deliveryPeriod?.id,
            futuresContractId: contract.futuresContract?.id,
            locationId: contract.location?.id,
            regionId: contract.region?.id,
            customerAccountId: contract.customerAccount?.accountId,
            traderId: contract.trader.userId,
            deliveryMethod: contract.deliveryMethod,
            freightFee: contract.freightFee,
            deliveryTerms: contract.deliveryTerms,
            customerContractId: contract.customerContractId,
            htaFee: contract.htaFee,
            basis: contract.basis,
            premium: contract.premium,
            cashPrice: contract.cashPrice,
            finalPrice: contract.finalPrice,
            futuresPrice: contract.futuresPrice,
            isBasisLocked: contract.isBasisLocked,
            quantityContracted: contract.quantityContracted,
            expirationDate: formatUTCDate(contract.expirationDate, "yyyy-MM-DD"),
            paymentOption: contract.paymentOption,
            deferredPayDate: contract.deferredPayDate ? formatUTCDate(contract.deferredPayDate, "yyyy-MM-DD") : null,
            interestStartDate: contract.interestStartDate ? formatUTCDate(contract.interestStartDate, "yyyy-MM-DD") : null,
            interestRate: contract.interestRate,
            comments: contract.comments,
            deliveryPeriodStartDate: contract.deliveryPeriodStartDate ? formatUTCDate(contract.deliveryPeriodStartDate, "yyyy-MM-DD"): null,
            deliveryPeriodEndDate: contract.deliveryPeriodEndDate ? formatUTCDate(contract.deliveryPeriodEndDate, "yyyy-MM-DD"): null,
            isSupervisorEditing: contract.isSupervisorEditing,
            fees: contract.fees,
            customFields: contract.customFields
        };

        const response = await this.getClient().put<Contract>(`/offers/v1/contracts/${contract.id}`, request);
        return response.data;
    }

    @ThrowsValidationError()
    async patchContract(contractId: string, request: PatchContractRequest): Promise<Contract> {

        const operations: PatchOperation[] = [];

        if (request.deliveryTerms !== undefined) {
            operations.push(new PatchOperation(OperationType.Replace, "/deliveryTerms", request.deliveryTerms));
        }

        if (request.flag !== undefined) {
            operations.push(new PatchOperation(OperationType.Replace, "/flag", request.flag));
        }

        const response = await this.getClient().patch<Contract>(`/offers/v1/contracts/${contractId}`, operations);
        
        return response.data;
    }

    @ThrowsValidationError()
    async closeContract(contract: Contract): Promise<Contract> {
        const response = await this.getClient().put<Contract>(`/offers/v1/contracts/${contract.id}/close`);
        return response.data;
    }

    @ThrowsValidationError()
    async archiveContract(contract: Contract): Promise<Contract> {
        const response = await this.getClient().put<Contract>(`/offers/v1/contracts/${contract.id}/archive`);
        return response.data;
    }

    @ThrowsValidationError()
    async unarchiveContract(contract: Contract): Promise<Contract> {
        const response = await this.getClient().put<Contract>(`/offers/v1/contracts/${contract.id}/unarchive`);
        return response.data;
    }

    @ReturnsNullOn404()
    async getWorkingQuantities(contractId: number): Promise<number> {
        const response = await this.getClient().get<number>(`/offers/v1/contracts/${contractId}/working-quantities`);
        return response.data;
    }

    async listContractPricing(contractId: number): Promise<ContractPricing[]> {
        const response = await this.getClient().get<ContractPricing[]>(`/offers/v1/contracts/${contractId}/pricing`);
        return response.data;
    }

    async listContractHistory(contractId: number): Promise<ContractHistory[]> {
        const response = await this.getClient().get<ContractHistory[]>(`/offers/v1/contracts/${contractId}/history`);
        return response.data;
    }

    async listContractOfferHistory(contractId: number): Promise<ListItemsResponse<OfferHistory>> {
        const response = await this.getClient().get<ListItemsResponse<OfferHistory>>(`/offers/v1/contracts/${contractId}/contract-offer-history`);
        return response.data;
    }

    async getErpSyncFailureReason(contractId: string): Promise<string> {
        const response = await this.getClient().get<string>(`/offers/v1/contracts/${contractId}/erp-sync-failure-reason`);
        return response.data;
    }

    async retryContractSyncingToErp(contract: Contract): Promise<any> {
        await this.getClient().put(`/offers/v1/contracts/${contract.id}/retry-erp-sync`);
    }

    async listContractSubTypes(contractType: ContractType, customerWillSell: boolean, cropYearId: string, paymentOption: PaymentOption): Promise<ContractSubType[]> {
        const response = await this.getClient().get<ContractSubType[]>('/offers/v1/contract-sub-types', {
            params: {
                contractType,
                customerWillSell,
                cropYearId,
                paymentOption
            }
        });
        return response.data;
    }

    @ThrowsValidationError()
    async rollContracts(request: RollContractsRequest): Promise<any> {
        const response = await this.getClient().post('/offers/v1/contracts/roll', request);
        
        return response.data;
    }
    
    async listContractInventory(contractId: number): Promise<ContractInventory[]> {
        const response = await this.getClient().get<ContractInventory[]>(`/offers/v1/contracts/${contractId}/inventory`);
        return response.data;
    }
}

interface ContractRequestBase {
    contractSubType?: string;
    deliveryPeriodId: string;
    futuresContractId: number;
    locationId: string;
    regionId: string;
    customerAccountId: string;
    deliveryMethod: DeliveryMethod;
    freightFee?: number;
    htaFee?: number;
    deliveryTerms?: string;
    customerContractId?: string,
    premium?: number;
    basis: number;
    cashPrice: number;
    futuresPrice: number;
    finalPrice: number;
    isBasisLocked: boolean;
    quantityContracted: number;
    expirationDate: string;
    paymentOption: PaymentOption;
    deferredPayDate: string;
    interestStartDate?: string;
    interestRate?: number;
    comments: string;
    deliveryPeriodStartDate: string;
    deliveryPeriodEndDate: string;
    fees: FeeThin[];
    customFields: CustomFieldThin[];
}

interface CreateContractRequest extends ContractRequestBase {
    commodityId: number;
    customerWillSell: boolean;
    contractType: ContractType;
    currency: CurrencyEnum;    
    customerId: string;
    sourceContractId: number;
    trader: Trader;
    contractLocationId: string;
    disableErpSync: boolean;
}

interface UpdateContractRequest extends ContractRequestBase {
    isSupervisorEditing: boolean;
    traderId: string;
}

interface PatchContractRequest {
    deliveryTerms?: string;
    flag?: boolean;
}

export type ListContractsFilter = {
    contractType?: ContractType;
    locations?: PhysicalLocation[];
    commodities?: Commodity[];
    willSell?: boolean;
    traders?: Trader[];

    sortColumn?: string;
    sortDirection? : string;
    startDate?: Date;
    endDate?: Date;
    displayContracts?: number;
    customerId?: string;    
    customerAccount?: string;
    deliveryPeriodId?: string;
}

export type RollContractsRequest = {
    contractIds: string[];
    rollToDeliveryPeriodId: string;
    rollToFuturesContractId?: number;
    rollFee: number;
    spread: number;
    expirationDate: string;
}

const contractService = new ContractService();
export default contractService;