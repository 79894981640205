export enum Partner {
    AgTrax = 1,
    Barchart = 2,
    DTN = 4,
    Oakland = 5,
    Agris = 6,
    Agvance = 7,
    EverAg = 8,
    AgVantage = 9,
    AgVision = 10,
    AgTech = 11
}

export function getPartnerName(partner: Partner): string {
    switch (partner) {
        case Partner.AgTrax:
            return "AgTrax";
        case Partner.Barchart:
            return "Barchart";
        case Partner.DTN:
            return "DTN";
        case Partner.Oakland:
            return "Oakland";
        case Partner.Agris:
            return "Agris";
        case Partner.Agvance:
            return "Agvance";
        case Partner.EverAg:
            return "EverAg";
        case Partner.AgVantage:
            return "AgVantage";
        case Partner.AgVision:
            return "AgVision";
        case Partner.AgTech:
            return "AgTech";
        default:
            return "Unknown import system";
    }
}