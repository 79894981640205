import { Partner } from "features/settings/importMapping/partner";

export enum ErpSystem {
    Agris = 1,
    AgTrax = 2,
    Oakland = 3,
    Agvance = 4,
    EverAg = 5,
    AgVantage = 6,
    AgVision = 7,
    AgTech = 8,
}

export class PartnerOptions {
    partner: Partner;
    isHedgeOrderExportSupported: boolean;
}

export function getErpSystemName(erpSystem: ErpSystem): string {
    switch (erpSystem) {
        case ErpSystem.AgTrax:
            return "AgTrax";
        case ErpSystem.Agris:
            return "Agris";
        case ErpSystem.Oakland:
            return "Oakland";
        case ErpSystem.Agvance:
            return "Agvance";
        case ErpSystem.EverAg:
            return "EverAg";
        case ErpSystem.AgVantage:
            return "AgVantage";
        case ErpSystem.AgVision:
            return "AgVision";
        case ErpSystem.AgTech:
            return "AgTech";
        default:
            return "Unknown ERP system";
    }
}

export function getErpSystemsForPartner(partner: Partner): ErpSystem[] {
    switch (partner) {
        case Partner.AgTrax:
            return [ErpSystem.AgTrax];
        case Partner.Agris:
            return [ErpSystem.Agris];
        case Partner.Oakland:
            return [ErpSystem.Oakland];
        case Partner.Agvance:
            return [ErpSystem.Agvance];
        case Partner.EverAg:
            return [ErpSystem.EverAg];
        case Partner.AgVantage:
            return [ErpSystem.AgVantage];
        case Partner.AgVision:
            return [ErpSystem.AgVision];
        case Partner.AgTech:
            return [ErpSystem.AgTech];
        default:
            throw new Error("Unrecognized import system " + partner);
    }
}