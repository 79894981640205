export enum OrderType {
    Market = 1,
    Limit,
    ManualEntry,
    Iceberg,
    Tas
}

export const AllOrderTypes: OrderType[] = [
    OrderType.Market,
    OrderType.Limit,
    OrderType.Tas
];

export const AllOrderTypesWithManualEntry: OrderType[] = [
    OrderType.Market,
    OrderType.Limit,
    OrderType.ManualEntry,
    OrderType.Tas
];

export const FlatCashOrderTypes: OrderType[] = [
    OrderType.Market,
    OrderType.Limit
]

export function getOrderTypeName(orderType: OrderType): string {
    switch (orderType) {
        case OrderType.Market:
            return "Market";
        case OrderType.Limit:
            return "Limit";
        case OrderType.ManualEntry:
            return "Manual Entry";
        case OrderType.Iceberg:
            return "Iceberg";
        case OrderType.Tas:
            return "TAS";
        default:
            return "";
    }
}